import * as React from "react";
import { isLoggedIn } from "../services/auth";
import { useEffect } from "react";
import { navigate } from "gatsby";
import { withPrefix } from "gatsby";
import Helmet from "react-helmet";
import Header1 from "../components/header";
import Footer1 from "../components/footer-1";
import { useState } from "react";

const policyStyle = {
  width: '100%',
  float: 'left',
  fontFamily: '"Roboto", sans-serif',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '22px',
  paddingBottom: '16px',
}

function AdvancedTab() {
  const [rw, setRw] = React.useState("512");
  const [rh, setRh] = React.useState("512");
  const [scale, setScale] = React.useState("512");
  const [steps, setSteps] = React.useState("512");
  const [nimg, setNimg] = React.useState("512");
  const [input, setInput] = React.useState("");
  const [step, setStep] = React.useState("");
  const [model, setModel] = React.useState("");
  const [checked, setChecked] = React.useState(true);
  var heading = [
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
  ];
  // let db= Array(9).fill().map((row,index)=>
  // new Array(11).fill('id: '+(index+1)))
  // console.log("array: ",db)
  var cfg = [
    {
      id: 40,
      value: true,
    },
    {
      id: 41,
      value: true,
    },
    {
      id: 42,
      value: true,
    },
    {
      id: 43,
      value: true,
    },
    {
      id: 44,
      value: true,
    },
    {
      id: 45,
      value: true,
    },
    {
      id: 46,
      value: true,
    },
    {
      id: 47,
      value: true,
    },
    {
      id: 48,
      value: true,
    },
    {
      id: 49,
      value: true,
    },
    {
      id: 50,
      value: true,
    },
  ];
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
    }
  }, []);

  const ChangeValue1 = (event) => {
    setRw(event.target.value);
  };
  const ChangeValue2 = (event) => {
    setRh(event.target.value);
  };
  const ChangeValue3 = (event) => {
    setScale(event.target.value);
  };
  const ChangeValue4 = (event) => {
    setSteps(event.target.value);
  };
  const ChangeValue5 = (event) => {
    setNimg(event.target.value);
  };
  const test = (id) => {
    document.getElementById(id).checked = false;
  };

  const handleChange = (s) => {
    setStep(s.target.value);
    // console.log("Selected!!",step);
  };
  const handleModelChange = (m) => {
    setModel(m.target.value);
    // console.log("Selected!!",model);
  };

  return (
    <>
      <div className="container-main alt" id="page">
        <Header1></Header1>
        <Helmet>
          <script
            src={withPrefix("assets/js/jquery.min.js")}
            type="text/javascript"
          />
          <script
            src={withPrefix("assets/js/popper.min.js")}
            type="text/javascript"
          />
          <script
            src={withPrefix("assets/js/bootstrap.min.js")}
            type="text/javascript"
          />
          <script
            src={withPrefix("assets/js/custom.js")}
            type="text/javascript"
          />
        </Helmet>

        <main className="content-main">
          <div className="product-tabs">
            <div className="container">
              <h1>Advanced Settings</h1>

              <div className="tab-links2">
                <ul className="nav">
                  <li>
                    <a href="#default" data-bs-toggle="tab">
                      Default
                    </a>
                  </li>
                  <li>
                    <a href="#advanced" className="active" data-bs-toggle="tab">
                      Advanced
                    </a>
                  </li>
                  <li>
                    <a href="#bulk" data-bs-toggle="tab">
                      Bulk
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content">
                <div className="tab-pane" id="default">
                  <div className="box">
                    <div className="search-form">
                      <form method="get" action="#">
                        <label>Enter a detailed description:</label>
                        <div className="textarea-box">
                          <textarea
                            name="commentsart2"
                            id="commentsart2"
                            cols="5"
                            rows="5"
                            placeholder="a colorful abstract textile pattern featuring glitter and sparkles and unicorns, seamless, 8K, intricate, contest winner"
                          ></textarea>
                        </div>
                      </form>
                    </div>
                    <p style={policyStyle}>By clicking “Generate Art” I’m agreeing to the site-wide <a href="/content-policy" target="_blank"> content policy.</a></p>
                    <p style={policyStyle}>Images generated by stable diffusion. <a href="https://stability.ai" target="_blank">Learn more.</a></p>
                    <div className="bottom-btn">
                      <div className="btn-out">
                        <a href="#" className="btn">
                          GENERATE ART
                        </a>
                      </div>
                      <div className="image-count">
                        <a href="#">.20 Credits / Image</a>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-box">
                    <span>
                      *Step count impacts price. <a href="#">Learn More.</a>
                    </span>
                  </div>
                </div>

                <div className="tab-pane active" id="advanced">
                  <div className="box">
                    <div className="form-box">
                      <div className="form-row">
                        <div className="aside-out">
                          <div className="aside">
                            <h4>
                              Resolution{" "}
                              <span className="sml">/ 32 pixel resolution</span>
                            </h4>
                            <div className="resolution-box">
                              <div className="resolution-bar">
                                <div className="txt-l">W</div>
                                <div className="range-slider">
                                  <input
                                    className="range-slider__range"
                                    type="range"
                                    min="0"
                                    max="1000"
                                    value={rw}
                                    onChange={ChangeValue1}
                                  />
                                  <span className="range-slider__value">{rw}</span>
                                </div>
                              </div>
                            </div>

                            <div className="resolution-box">
                              <div className="resolution-bar">
                                <div className="txt-l">H</div>
                                <div className="range-slider">
                                  <input
                                    className="range-slider__range"
                                    type="range"
                                    min="0"
                                    max="1000"
                                    value={rh}
                                    onChange={ChangeValue2}
                                  />
                                  <span className="range-slider__value">{rh}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="aside">
                            <h4>CFG Scale</h4>
                            <div className="resolution-box">
                              <div className="resolution-bar alt">
                                <div className="range-slider">
                                  <input
                                    className="range-slider__range"
                                    type="range"
                                    min="0"
                                    max="1000"
                                    value={scale}
                                    onChange={ChangeValue3}
                                  />
                                  <span className="range-slider__value">
                                    {scale}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="aside-out">
                          <div className="aside">
                            <h4>Steps</h4>
                            <div className="resolution-box">
                              <div className="resolution-bar alt">
                                <div className="range-slider">
                                  <input
                                    className="range-slider__range"
                                    type="range"
                                    min="0"
                                    max="1000"
                                    value={steps}
                                    onChange={ChangeValue4}
                                  />
                                  <span className="range-slider__value">
                                    {steps}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="aside">
                            <h4>Number of Images</h4>
                            <div className="resolution-box">
                              <div className="resolution-bar alt">
                                <div className="range-slider">
                                  <input
                                    className="range-slider__range"
                                    type="range"
                                    min="0"
                                    max="1000"
                                    value={nimg}
                                    onChange={ChangeValue5}
                                  />
                                  <span className="range-slider__value">
                                    {nimg}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-field">
                        <form method="get" action="#">
                          <ul>
                            <li>
                              <label>Steps</label>
                              <select
                                id="ims"
                                name="select"
                                className="ims"
                                value={step}
                                onChange={handleChange}
                              >
                                <option value="kims">k_Ims</option>
                                <option value="kims2">k_Ims 2</option>
                                <option value="kims3">k_Ims 3</option>
                              </select>
                            </li>
                            <li className="input-with-checkbox">
                              <div className="check-options">
                                <input
                                  id="checkbox2"
                                  name="checkbox2"
                                  type="checkbox"
                                  className="styled"
                                />
                                <label for="checkbox2">Seed</label>
                              </div>
                              <input
                                id="firstname"
                                name="firstname"
                                type="text"
                                placeholder="37832786"
                                onChange={(e) => {
                                  setInput(e.target.value);
                                  // console.log("input :", input)
                                }}
                              />
                            </li>

                            <li>
                              <label>Ai Model</label>
                              <select
                                id="stable"
                                name="select"
                                className="stable"
                                value={model}
                                onChange={handleModelChange}
                              >
                                <option value="stablediffusion1">
                                  Stable Diffusion v1.5
                                </option>
                                <option value="stablediffusion2">
                                  Stable Diffusion v1.6
                                </option>
                                <option value="stablediffusion3">
                                  Stable Diffusion v1.7
                                </option>
                              </select>
                            </li>
                          </ul>
                        </form>
                      </div>

                      <div className="bottom-btn">
                        <div className="btn-out">
                          <a
                            href="#"
                            className="btn"
                            onClick={() =>
                              console.log(
                                "form data: ",
                                rw,
                                rh,
                                steps,
                                scale,
                                nimg,
                                step,
                                model,
                                input
                              )
                            }
                          >
                            GENERATE ART
                          </a>
                        </div>
                        <div className="right-r">
                          <div className="image-count">
                            <a href="#">.20 Credits / Image</a>
                          </div>
                          <div className="check-option-btm">
                            <div className="check-options">
                              <input
                                id="checkbox"
                                name="checkbox"
                                type="checkbox"
                                className="styled"
                              />
                              <label for="checkbox">Lock Settings</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane" id="bulk">
                  <div className="box">
                    <div className="product-table">
                      <div className="table-inn">
                        <div className="table-heading">
                          <h4>Bulk Image Control</h4>
                          <div className="selected-btn-out">
                            <span className="selected-btn">
                              98 Selected : <span className="blue">81 Credits</span>
                            </span>
                          </div>
                        </div>

                        <table>
                          <thead>
                            <tr>
                              <th>
                                <strong>
                                  Steps{" "}
                                  <em>
                                    <img
                                      src="assets/img/polygon-right.png"
                                      alt="polygon-right"
                                    />
                                  </em>
                                </strong>
                              </th>

                              {heading.map((d, index) => (
                                <th key={index}>{d}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>6</td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox3"
                                    name="checkbox3"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox3"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox4"
                                    name="checkbox4"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox4"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox5"
                                    name="checkbox5"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox5"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox6"
                                    name="checkbox6"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox6"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox7"
                                    name="checkbox7"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox7"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox8"
                                    name="checkbox8"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox8"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox9"
                                    name="checkbox9"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox9"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox10"
                                    name="checkbox10"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox10"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox11"
                                    name="checkbox11"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox11"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox12"
                                    name="checkbox12"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox12"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox13"
                                    name="checkbox13"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox13"></label>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>6.5</td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox14"
                                    name="checkbox14"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox14"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox15"
                                    name="checkbox15"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox15"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox16"
                                    name="checkbox16"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox16"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox17"
                                    name="checkbox17"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox17"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox18"
                                    name="checkbox18"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox18"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox19"
                                    name="checkbox19"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox19"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox20"
                                    name="checkbox20"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox20"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox21"
                                    name="checkbox21"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox21"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox22"
                                    name="checkbox22"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox22"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox23"
                                    name="checkbox23"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox23"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox24"
                                    name="checkbox24"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox24"></label>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>7</td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox25"
                                    name="checkbox25"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox25"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox26"
                                    name="checkbox26"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox26"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox27"
                                    name="checkbox27"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox27"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox28"
                                    name="checkbox28"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox28"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox29"
                                    name="checkbox29"
                                    type="checkbox"
                                    className="styled"
                                  />
                                  <label for="checkbox29"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox30"
                                    name="checkbox30"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox30"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox31"
                                    name="checkbox31"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox31"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox32"
                                    name="checkbox32"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox32"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox33"
                                    name="checkbox33"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox33"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox34"
                                    name="checkbox34"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox34"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox35"
                                    name="checkbox35"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox35"></label>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>7.5</td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox36"
                                    name="checkbox36"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox36"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox37"
                                    name="checkbox37"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox37"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox38"
                                    name="checkbox38"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox38"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox39"
                                    name="checkbox39"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox39"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox40"
                                    name="checkbox40"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox40"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox41"
                                    name="checkbox41"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox41"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox42"
                                    name="checkbox42"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox42"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox43"
                                    name="checkbox43"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox43"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox44"
                                    name="checkbox44"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox44"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox45"
                                    name="checkbox45"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox45"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox46"
                                    name="checkbox46"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox46"></label>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>8</td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox47"
                                    name="checkbox47"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox47"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox48"
                                    name="checkbox48"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox48"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox49"
                                    name="checkbox49"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox49"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox50"
                                    name="checkbox50"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox50"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox51"
                                    name="checkbox51"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox51"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox52"
                                    name="checkbox52"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox52"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox53"
                                    name="checkbox53"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox53"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox54"
                                    name="checkbox54"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox54"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox55"
                                    name="checkbox55"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox55"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox56"
                                    name="checkbox56"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox56"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox57"
                                    name="checkbox57"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox57"></label>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>8.5</td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox58"
                                    name="checkbox58"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox58"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox59"
                                    name="checkbox59"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox49"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox60"
                                    name="checkbox60"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox60"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox61"
                                    name="checkbox61"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox61"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox62"
                                    name="checkbox62"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox62"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox63"
                                    name="checkbox63"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox63"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox64"
                                    name="checkbox64"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox64"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox65"
                                    name="checkbox65"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox65"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox66"
                                    name="checkbox66"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox66"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox67"
                                    name="checkbox67"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox67"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox68"
                                    name="checkbox68"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox68"></label>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>9</td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox69"
                                    name="checkbox69"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox69"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox70"
                                    name="checkbox70"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox70"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox71"
                                    name="checkbox71"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox71"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox72"
                                    name="checkbox72"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox72"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox73"
                                    name="checkbox73"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox73"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox74"
                                    name="checkbox74"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox74"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox75"
                                    name="checkbox75"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox75"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox76"
                                    name="checkbox76"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox76"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox77"
                                    name="checkbox77"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox77"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox78"
                                    name="checkbox78"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox78"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox79"
                                    name="checkbox79"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox79"></label>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>9.5</td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox80"
                                    name="checkbox80"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox80"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox81"
                                    name="checkbox81"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox81"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox82"
                                    name="checkbox82"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox82"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox83"
                                    name="checkbox83"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox83"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox84"
                                    name="checkbox84"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox84"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox85"
                                    name="checkbox85"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox85"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox86"
                                    name="checkbox86"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox86"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox87"
                                    name="checkbox87"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox87"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox88"
                                    name="checkbox88"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox88"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox89"
                                    name="checkbox89"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox89"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox90"
                                    name="checkbox90"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox90"></label>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>10</td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox91"
                                    name="checkbox91"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox91"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox92"
                                    name="checkbox92"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox92"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox93"
                                    name="checkbox93"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox93"></label>
                                </div>
                              </td>
                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox94"
                                    name="checkbox94"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox94"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox95"
                                    name="checkbox95"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox95"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox96"
                                    name="checkbox96"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox96"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox97"
                                    name="checkbox97"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox97"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox98"
                                    name="checkbox98"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox98"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox99"
                                    name="checkbox99"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox99"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox100"
                                    name="checkbox100"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox100"></label>
                                </div>
                              </td>

                              <td>
                                <div className="check-options">
                                  <input
                                    id="checkbox101"
                                    name="checkbox101"
                                    type="checkbox"
                                    className="styled"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                  />
                                  <label for="checkbox101"></label>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="btm-sml-txt">
                          <em>
                            <img
                              src="assets/img/polygon-up.png"
                              alt="polygon-up"
                            />
                          </em>{" "}
                          <br /> CFG Scale
                        </div>
                      </div>

                      <div className="bottom-btn">
                        <div className="btn-out">
                          <a href="#" className="btn">
                            GENERATE ART
                          </a>
                        </div>
                        <div className="image-count">
                          <a href="#">.20 Credits / Image</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-box">
                    <span>
                      *Step count impacts price. <a href="#">Learn More.</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer1></Footer1>
      </div>
    </>
  );
}
export default AdvancedTab;
